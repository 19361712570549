<template>
  <div>
    <v-combobox
      v-model="model"
      :filter="filter"
      :hide-no-data="!search"
      :search-input.sync="search"
      hide-selected
      hide-spin-buttons
      label="اكتب الميزة"
      multiple
      small-chips
      outlined
    >
      <template v-slot:no-data>
        <v-list-item>
          <span class="subheading">إنشاء </span>
          <v-chip
            :color="`red lighten-3`"
            label
            small
          >
            {{ search }}
          </v-chip>
        </v-list-item>
      </template>
      <template v-slot:selection="{ attrs, item, parent, selected }">
        <v-chip
          v-if="item === Object(item)"
          v-bind="attrs"
          :color="`blue lighten-3`"
          :input-value="selected"
          label
          small
        >
          <span class="pr-2">
            {{ item.text }}
          </span>
          <v-icon
            small
            @click="parent.selectItem(item); handleDelete(item)"
          >
            $delete
          </v-icon>
        </v-chip>
      </template>
      <template v-slot:item="{ index, item }">
        <v-text-field
          v-if="editing === item"
          v-model="editing.text"
          autofocus
          flat
          background-color="transparent"
          hide-details
          solo
          @keyup.enter="edit(index, item)"
        />
        <v-chip
          v-else
          :color="`${item.color} lighten-3`"
          dark
          label
          small
        >
          {{ item.text }}
        </v-chip>
        <v-spacer />
        <v-list-item-action @click.stop>
          <v-btn
            icon
            @click.stop.prevent="edit(index, item)"
          >
            <v-icon>{{ editing !== item ? 'mdi-pencil' : 'mdi-check' }}</v-icon>
          </v-btn>
        </v-list-item-action>
      </template>
    </v-combobox>
  </div>
</template>

<script>
  export default {
    name: 'CustomComobox',
    props: {
      value: {
        type: Array,
        default: () => ([]),
      },
    },
    data: () => ({
      activator: null,
      attach: null,
      colors: ['green', 'purple', 'indigo', 'cyan', 'teal', 'orange'],
      editing: null,
      editingIndex: -1,
      items: [
        { header: 'Select an option or create one' },
        {
          text: 'Foo',
          color: 'blue',
        },
        {
          text: 'Bar',
          color: 'red',
        },
      ],
      menu: false,
      model: [
      ],
      x: 0,
      search: null,
      y: 0,
      counter: 0,
    }),
    watch: {
      // watch it just for one time
      value (val, prev) {
        if (this.counter === 1) return
        if (val.length === prev.length) return
        this.model = val
        this.counter = 1
      },
      model (val, prev) {
        if (val.length === prev.length) return

        this.model = val.map(v => {
          if (typeof v === 'string') {
            this.$emit('input', [...this.value, v])
            v = {
              text: v,
              color: 'red',
            }

            this.items.push(v)
          }

          return v
        })
      },
    },
    created () {
      console.log('mnb0', this.value)
      this.model = this.value.map(item => ({ text: item, color: 'red' }))
    },
    methods: {
      handleDelete ({ text }) {
        this.$emit('input', this.value.filter(item => item !== text))
      },
      edit (index, item) {
        if (!this.editing) {
          this.editing = item
          this.editingIndex = index
        } else {
          this.editing = null
          this.editingIndex = -1
        }
      },
      filter (item, queryText, itemText) {
        if (item.header) return false

        const hasValue = val => val != null ? val : ''

        const text = hasValue(itemText)
        const query = hasValue(queryText)

        return text.toString()
          .toLowerCase()
          .indexOf(query.toString().toLowerCase()) > -1
      },
    },
  }
</script>
