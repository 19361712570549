<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <v-card class="py-5">
      <v-card-title class="mb-10">
        <h2>{{ this.$route.params.id ? 'تعديل الخطة' : 'إضافة خطة' }}</h2>
      </v-card-title>
      <hr class="mb-4">
      <template>
        <v-form
          @submit.prevent="submitForm()"
        >
          <v-container fluid>
            <v-row class="">
              <v-col
                cols="12"
                sm="5"
                md="5"
              >
                <p>إسم الخطة</p>
                <v-text-field
                  v-model="data.name_ar"
                  outlined
                  required
                />
              </v-col>
            </v-row>
            <v-row class="">
              <v-col
                cols="12"
                sm="4"
                md="4"
              >
                <p>السعر</p>
                <v-text-field
                  v-model="data.price_per_month"
                  outlined
                  required
                />
              </v-col>
            </v-row>
            <v-row class="">
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <p>الوصف</p>
                <v-text-field
                  v-model="data.description_ar"
                  outlined
                  required
                />
              </v-col>
            </v-row>
            <v-row class="">
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <p>مميزات</p>
                <custom-comobox v-model="data.features" />
              </v-col>
            </v-row>
            <v-spacer />
            <v-spacer />
            <div class="text-center mt-10">
              <v-btn
                to="/Users"
                type="submit"
                class="mx-2"
                color="indigo"
              >
                رجوع
              </v-btn>
              <v-btn
                type="submit"
                class="mx-2"
                color="indigo"
                :loading="isLoading"
                @click.prevent="submit"
              >
                {{ this.$route.params.id ? 'حفظ' : 'اضافة' }}
              </v-btn>
            </div>
          </v-container>
        </v-form>
      </template>
    </v-card>
  </v-container>
</template>

<script>
  import customComobox from '../component/customComobox.vue'
  import { mapActions, mapState } from 'vuex'

  export default {
    name: 'Package',
    components: { customComobox },
    data: () => ({
      showSuccess: false,
      successMessage: '',
      showError: false,
      errorMessage: '',
      default: {
        name_ar: '',
        name_en: 'xxx',
        price_per_month: 0,
        price_per_year: 0,
        description_en: 'xxx',
        description_ar: '',
        transaction_count: 0,
        is_active: 1,
        features: [],
      },
    }),
    mounted () {
      if (this.$route.params.id) {
        this.getById(this.$route.params.id)
      } else {
        this.setDefaultItem(this.default)
      }
    },
    computed: {
      ...mapState('package', {
        isLoading: state => state.isLoading,
        data: state => state.singleItem,
      }),
    },
    methods: {
      ...mapActions('package', [
        'addItem',
        'updateItem',
        'getById',
        'setDefaultItem',
      ]),
      submit () {
        if (this.$route.params.id) {
          this.updateItem({ id: this.$route.params.id, data: this.data })
        } else {
          this.addItem(this.data)
        }
      },
    },
  }
</script>
